@tailwind base;
@tailwind utilities;
@tailwind components;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }

  body {
    color: white;
    background: black;
  }
}

.PhoneInput input {
  outline: none;
}

/* Thiết lập độ rộng của thanh cuộn */
::-webkit-scrollbar {
  width: 0px;
}

/* Thiết lập màu nền của thanh cuộn */
::-webkit-scrollbar-track {
  background-color: none;
}

/* Thiết lập màu của thanh cuộn */
::-webkit-scrollbar-thumb {
  background-color: white;
}

._line-clamp_2 {
  overflow: hidden;
  /* display: block; */
  font-size: 1rem;
  line-height: 1.5rem;
  height: 3rem;
}

.rounded-l-full {
  border-radius: 0px !important;
}

.rounded-r-full {
  border-radius: 0px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
}
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:focus {
  --tw-ring-color: transparent;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}
