.PhoneInputInput {
  background-color: white;
  padding-left: 8px;
  padding-right: 8px;
  /* border-left: 1px solid #f1f0f2; */
}

.PhoneInputCountry {
  gap: 5px;
}

