.react-datepicker__year-dropdown .react-datepicker__year-option:first-child::before {
  content: "\25B2";
  position: absolute;
  top: 2%;
  left: 45%;
  margin: auto;
}

.react-datepicker__year-dropdown::after {
  content: "\25BC";
  position: absolute;
  bottom: 2%;
  left: 45%;
  margin: auto;
}

.react-daterange-picker__wrapper {
  border: none !important;
}
.react-daterange-picker__inputGroup{
  min-width: auto !important;
}

.react-daterange-picker__range-divider{
  padding: 0px 8px !important;
}
